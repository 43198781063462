
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import UtilService from "../../../utils/utils";
import ExportOptions from "@/components/export-options.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../services/filter-service";
import PopUp from "@/components/PopUp.vue";
import Moment from "moment";

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
    PopUp,

    ExportOptions,
    FilterComponent,
  },
})
export default class Employee extends Vue {

  public triggerNotification = false;
  public loading = false;
  public fields = [
    { key: "employee_code", sortable: true },
    { key: "employee_title", sortable: true },
    { key: "d1" },
    { key: "d2" },
    { key: "d3" },
    { key: "d4" },
    { key: "d5" },
    { key: "d6" },
    { key: "d7" },
    { key: "d8" },
    { key: "d9" },
    { key: "d10" },
    { key: "d11" },
    { key: "d12" },
    { key: "d13" },
    { key: "d14" },
    { key: "d15" },
    { key: "d16" },
    { key: "d17" },
    { key: "d18" },
    { key: "d19" },
    { key: "d20" },
    { key: "d21" },
    { key: "d22" },
    { key: "d23" },
    { key: "d24" },
    { key: "d25" },
    { key: "d26" },
    { key: "d27" },
    { key: "d28" },
    { key: "d29" },
    { key: "d30" },
    { key: "d31" },
    { key: "P" },
    { key: "L" },
    { key: "OT" },
    { key: "LE" },
  ];
  public columns = [
    { label: "Employee Code", field: "employee_code" },
    { label: "Employee Title", field: "employee_title" },
    { label: "D1", field: "d1" },
    { label: "D2", field: "d2" },
    { label: "D3", field: "d3" },
    { label: "D4", field: "d4" },
    { label: "D5", field: "d5" },
    { label: "D6", field: "d6" },
    { label: "D7", field: "d7" },
    { label: "D8", field: "d8" },
    { label: "D9", field: "d9" },
    { label: "D10", field: "d10" },
    { label: "D11", field: "d11" },
    { label: "D12", field: "d12" },
    { label: "D13", field: "d13" },
    { label: "D14", field: "d14" },
    { label: "D15", field: "d15" },
    { label: "D16", field: "d16" },
    { label: "D17", field: "d17" },
    { label: "D18", field: "d18" },
    { label: "D19", field: "d19" },
    { label: "D20", field: "d20" },
    { label: "D21", field: "d21" },
    { label: "D22", field: "d22" },
    { label: "D23", field: "d23" },
    { label: "D24", field: "d24" },
    { label: "D25", field: "d25" },
    { label: "D26", field: "d26" },
    { label: "D27", field: "d27" },
    { label: "D28", field: "d28" },
    { label: "D29", field: "d29" },
    { label: "D30", field: "d30" },
    { label: "D31", field: "d31" },
  ];

  public widths = [
    60, 130, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
    20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
  ];
  error: any = "";
  fieldType = "";
  // For Modal
  modalShow = false;
  modalMessage = "";
  modalType = "";
  modalErrorMessage = [];
  errorMessages: any = [];
  saveData = true;

  closeModal() {
    this.modalShow = false;
    this.modalMessage = "";
    this.modalType = "";
    this.errorMessages = [];
  }
  showModal(message: string, type: "success" | "error") {
    this.modalShow = true;
    this.modalMessage = message;
    this.modalType = type;
  }
  showModalErrors(message: [], type: "errorArray") {
    this.modalShow = true;
    this.modalErrorMessage = message;
    this.modalType = type;
  }
  // modal end

  public import_file = null;
  public params_date: any = {};
  public import_params: any = {};
  public create: any = {};
  public ot: any = {};
  public leave: any = {};
  public late: any = {};

  public filter = null;
  public filterOn = [];
  public selected_items = [];
  public items = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }
  getRespectiveDate(params: any, type: string) {
    let dayNum;
    // 31 or 30 days?
    if (
      ["01", "03", "05", "07", "08", "10", "12"].includes(params.item.month)
    ) {
      dayNum = 31;
    } else if (["04", "06", "09", "11"].includes(params.item.month)) {
      dayNum = 30;
    } else {
      // If month is February, calculate whether it is a leap year or not
      const year = params.item.year;
      const isLeap = new Date(year, 1, 29).getMonth() === 1;
      dayNum = isLeap ? 29 : 28;
    }
    const minDate = new Date(`${params.item.year}-${params.item.month}-01`)
      .toISOString()
      .split("T")[0];
    const maxDate = new Date(
      `${params.item.year}-${params.item.month}-${dayNum}`
    )
      .toISOString()
      .split("T")[0];
    var input = document.getElementById(type);

    if (input) {
      input.setAttribute("min", minDate);
      input.setAttribute("max", maxDate);
    }
    return [minDate, maxDate];
  }
  onFiltered(filteredItems: any) { }
  mounted() { }
  get_data(params: any) {
    this.loading = true;
    // console.log(params);
    HRMService.get_attendance_sheet_by(params)
      .then((response) => {
        this.items = response.data.result;
        this.selected_items = this.items;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  refreshList() { }
  open_sidebar(params: any, type: any) {
    if (type == "create") {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-present");
      this.create["employee_id"] = params.item.employee;
      this.create["employee_title"] = params.item.employee_title;
      this.getRespectiveDate(params, "create-attend-input");
    } else if (type == "late") {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-late");
      this.late["employee_id"] = params.item.employee;
      this.late["employee_title"] = params.item.employee_title;
    } else if (type == "leave") {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-leave");
      this.leave["employee_id"] = params.item.employee;
      this.leave["employee_title"] = params.item.employee_title;
      this.getRespectiveDate(params, "create-leave-input");
    } else if (type == "ot") {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-overtime");
      this.ot["employee_id"] = params.item.employee;
      this.ot["employee_title"] = params.item.employee_title;
      this.getRespectiveDate(params, "create-ot-input");
    } else {
      this.showModal("Please validate the parameters", "error");
      // alert("Please validate the parameters");
    }
  }
  save(params: any, type: any) {
    if (type == "create") {
      let date = params["check_in"];
      let obj = {
        day: UtilService.get_formatted_date(date, "day", false, "-"),
        month: UtilService.get_formatted_date(date, "month", true, "-"),
        year: UtilService.get_formatted_date(date, "year", true, "-"),
        check_in: params["check_in"],
        employee_id: params["employee_id"],
        employee_title: params["employee_title"],
        status: params["status"],
        type: "create",
      };
      this.error = ""
      const keyMap = {
        'check_in': 'Select Day',
        'status': 'Type'
      }
      const error_list = UtilService.getEmptyKeys(params, ['check_in', 'status'])
      if (error_list.length > 0) {
        this.error = UtilService.generateErrorMessageFields(error_list, keyMap);
        this.fieldType = "create"
      }
      else {
        this.create_attendance(obj);
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-present");
        this.showModal("Attendance status of the day has been saved you can continue", "success")
      }
    } else if (type == "late") {
      let date = params["check_in"];
      // let obj = {
      //   day: UtilService.get_formatted_date(date, 'day', false, "-"),
      //   month: UtilService.get_formatted_date(date, 'month', true, "-"),
      //   year: UtilService.get_formatted_date(date, 'year', true, "-"),
      //   check_in: params['check_in'],
      //   check_out: UtilService.get_formatted_date(date, '', true, '-') + ' 05:00:00',
      //   employee_id: params['employee_id'],
      //   employee_title: params['employee_title'],
      //   status: 'LT',
      //   type: 'late'
      // }
      //   this.error = ""
      // const keyMap = {
      //   'check_in': 'No. of Hours'
      // }
      // const error_list = UtilService.getEmptyKeys(params, ['check_in'])
      // if (error_list.length > 0) {
      //   this.error = UtilService.generateErrorMessage(error_list);
      // this.fieldType = "late"
      // }
      // else {
      //   this.create_attendance(obj);
      // this.$root.$emit("bv::toggle::collapse", "sidebar-variant-late");
      // }
    } else if (type == "leave") {
      let date = new Date(params["check_in"]);
      let year = UtilService.get_formatted_date(date, "year", true, "-");
      let month = UtilService.get_formatted_date(date, "month", true, "-");
      let day = UtilService.get_formatted_date(date, "day", false, "-")
      let obj = {
        day: day,
        month: month,
        year: year,
        check_in: params["check_in"],
        check_out: params["check_in"],
        employee_id: params["employee_id"],
        employee_title: params["employee_title"],
        status: "L",
        type: "leave",
      };
      this.error = ""
      const keyMap = {
        'check_in': 'Select Day'
      }
      const error_list = UtilService.getEmptyKeys(params, ['check_in'])
      if (error_list.length > 0) {
        this.error = UtilService.generateErrorMessageFields(error_list, keyMap);
        this.fieldType = "leave";
      }
      else {
        this.create_attendance(obj);
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-leave");
        this.showModal("Leave has been saved you can continue", "success")
      }
    } else if (type == "ot") {
      let date = new Date(params["check_in"]);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let obj = {
        day: day,
        month: month,
        year: year,
        check_in: params["check_in"],
        check_out: params["check_out"],
        employee_id: params["employee_id"],
        employee_title: params["employee_title"],
        status: "OT",
        type: "ot",
      };
      this.error = ""
      const keyMap = {
        'check_in': 'Select Day',
        'check_out': 'No. of Hours'
      }
      const error_list = UtilService.getEmptyKeys(params, ['check_in', 'check_out'])
      if (error_list.length > 0) {
        this.error = UtilService.generateErrorMessageFields(error_list, keyMap);
        this.fieldType = "ot";
      }
      else {
        this.create_attendance(obj);
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-overtime");
        this.showModal("Over time has been saved you can continue", "success")
      }
    } else {
      this.error("Please validate the parameters");
      // alert("Please validate the parameters");
    }
  }
  create_attendance(obj: any) {
    this.loading = true;
    // console.log("crreate");
    const getObj = { attendancesheet_month: "", attendancesheet_year: "" };
    getObj["attendancesheet_month"] = obj["month"];
    getObj["attendancesheet_year"] = obj["year"];
    // console.log(obj)
    HRMService.create_attendance(obj)
      .then((response) => {
        // this.get_data(this.params_date);
        this.getAttendenceSheet(getObj);
        this.create = {};
        this.late = {};
        this.leave = {};
        this.ot = {};
        // this.items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  upload(params: any) {
    this.error = ""
    const error_list = UtilService.getEmptyKeys(params, ['year', 'month'])
    if (error_list.length > 0) {
      this.error = UtilService.generateErrorMessage(error_list)
    }
    else {
      this.showModal(
        "We will let you know when the attendance has been uploaded.",
        "success"
      );
      this.loading = true;
      HRMService.import_attendance(params)
        .then((response) => {
          if (response.data.result == "Already exist") {
            this.showModal("Already exist please generate new one", "error");
            this.import_params = {};
            this.import_file = null;
            this.loading = false;
            this.triggerNotification = true;
          }
          else {
            this.showModal("Attendance have been generated", "success");
            // alert("Generating the Attendance please wait");
            this.loading = false;
            this.triggerNotification = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          if (e?.response?.data?.result) {
            this.showModal(e.response.data.result, "error");
          }
          else { this.showModal("Not Genrated Something Wrong", "error"); }
        });
    }
  }
  filter_fields = [
    {
      type: "dropdown",
      label: "Select Year",
      name: "year",
      value: "",
      options: ["2023", "2022", "2021"],
      param_name: "attendancesheet_year",
      filter_type: "simple",
    },
    // { type: "dropdown", label: "Select Month", name: "month", value: "", param_name:"attendancesheet_month" ,options:['jan', '02','03','04','05','06','07','08','09','10','11','12']},
    {
      type: "dropdown",
      label: "Select Month",
      name: "month",
      value: "",
      param_name: "attendancesheet_month",
      options: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      filter_type: "simple",
    },
    // {type:"button", label:"Submit", name:"submit"}
  ];

  advance_filter_fields = [
    {
      type: "text",
      label: "Employee Title",
      name: "emp_title",
      value: "",
      param_name: "attendancesheet_title",
      filter_type: "advance",
    },
    {
      type: "text",
      label: "Employee Code",
      name: "emp_code",
      value: "",
      param_name: "attendancesheet_employee_code",
      filter_type: "advance",
    },
    //{ type: "text", label: "Created At", name: "created_at", value: "",param_name:"attendancesheet_created_at" },
  ];

  myObj: any = {};
  key: any = "";
  value: any = "";
  monthMap: any = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };
  monthForAdvSearch = "";
  yearForAdvSearch = "";

  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        if (element.label === "Select Month") {
          if (filter.param_name === "attendancesheet_month") {
            simpleFilteredObj[filter.param_name] = this.monthMap[filter.value];
            this.monthForAdvSearch = this.monthMap[filter.value];
          } else if (filter.param_name === "attendancesheet_year") {
            simpleFilteredObj[filter.param_name] = filter.value;
            this.yearForAdvSearch = filter.value;
          }
        } else {
          simpleFilteredObj[filter.param_name] = filter.value;
        }
      });
      if (
        simpleFilteredObj["attendancesheet_month"] != "" &&
        simpleFilteredObj["attendancesheet_year"] != ""
      ) {
        this.getRespectiveDate(
          {
            item: {
              month: simpleFilteredObj["attendancesheet_month"],
              year: simpleFilteredObj["attendancesheet_year"],
            },
          },
          "create-attend-input"
        );
        this.getRespectiveDate(
          {
            item: {
              month: simpleFilteredObj["attendancesheet_month"],
              year: simpleFilteredObj["attendancesheet_year"],
            },
          },
          "create-leave-input"
        );
        this.getAttendenceSheet(simpleFilteredObj);
      }
    } else if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      if (this.monthForAdvSearch == "") return;
      const advanceFilteredObj: AdvanceObj = {};
      advanceFilteredObj["attendancesheet_month"] = this.monthForAdvSearch;
      advanceFilteredObj["attendancesheet_year"] = this.yearForAdvSearch;

      this.advance_filter_fields.forEach((filter) => {
        if (filter.type === "text") {
          if (filter.param_name) {
            advanceFilteredObj[filter.param_name] = filter.value;
          }
        }
      });
      this.loading = true;
      FilterService.getAll(advanceFilteredObj).then((data) => {
        if (
          data.data.employees_attendance_sheet !== undefined &&
          data.data.employees_attendance_sheet !== null
        ) {
          this.loading = false;
          this.selected_items = data.data.employees_attendance_sheet;
        }
        // else{
        //   this.retrieve()
        // }
      });
    }

    //     interface MyObj {
    //   [key: string]: any;
    // }
    //   const myObj: MyObj = {};
    //     // console.log(element.value)
    //     if(element.type === 'date'){
    //       const startParam = element.min_param_name;
    //     const endParam = element.max_param_name;
    //     const date0 = new Date(element.range[0]);
    //     const date1 = new Date(element.range[1]);

    //     const formattedDate0 = Moment(date0).format('YYYY-MM-DD');
    //     const formattedDate1 = Moment(date1).format('YYYY-MM-DD');
    //     myObj[startParam] = formattedDate0;
    //     myObj[endParam] = formattedDate1;
    //     //   console.log(element.min_param_name)
    //     // console.log(element.max_param_name)
    //     if (element.value_2.length === 0) {this.get_data(myObj)}
    //       else{
    //       FilterService.getAll(myObj).then((data) => {
    //     this.selected_items =  (data.data.employees_attendance_sheet)
    //     })
    //   }
    //     }else{

    //       if (element.value.length === 0) {this.get_data(myObj)}
    //       else{
    //         if (element.label === 'Select Month') {
    //         myObj[element.param_name] = this.monthMap[element.value]
    //       }
    //       else{
    //         myObj[element.param_name] = element.value;
    //       }
    //       FilterService.getAll(myObj).then((data) => {
    //     this.selected_items =  (data.data.employees_attendance_sheet)
    //     })
    //       }
    //     }
  }
  getAttendenceSheet(obj: object) {
    this.loading = true;
    FilterService.getAll(obj).then((data) => {
      if (
        data.data.employees_attendance_sheet !== undefined &&
        data.data.employees_attendance_sheet !== null
      ) {
        this.loading = false;
        this.selected_items = data.data.employees_attendance_sheet;
      }
      // else{
      //   this.retrieve()
      // }
    });
  }
  updateFilterFields(fields: any[]) {
    this.filter_fields = fields;
  }
  refreshDateFeild(element: any) {
    element.range = [];
  }
  refreshFilters() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
    // this.get_data;
  }
  refreshAdvanceFields() {
    for (let field of this.advance_filter_fields) {
      if (field.type == "text") {
        field.value = "";
      }
    }
  }
  refreshRecord() {
    this.refreshFilters();
    this.selected_items = [];
  }
}
